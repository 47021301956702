import { useAuthenticationService, useFeatureToggleService } from 'admin-portal-shared-services';
import * as FeatureToggles from './config/featureToggles';

export function setupLocalEnv(): void {
  localStorage.setItem(
    'adminPortalSelectedLanguage',
    '{"name":"Português (Brasil)","abbreviation":"pt-BR"}'
  );
  useAuthenticationService().setAuthHeader(
    'Bearer eyJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InRlc3RAY2lhbmR0LmNvbSIsIm5hbWUiOiJuYW1lIiwiZ2l2ZW5fbmFtZSI6ImdpdmVuX25hbWUiLCJmYW1pbHlfbmFtZSI6ImZhbWlseV9uYW1lIiwiY291bnRyeSI6IkRPIiwiZXh0ZW5zaW9uX3RuY2FjY2VwdGVkZGF0ZXRpbWUiOjE2MTY0MTk2NDQsImNyZWF0ZWRPbiI6ImFkbWlucG9ydGFsIiwic2NvcGVzIjpbIi5Qcm9kdWN0cy4iLCIuQXR0cmlidXRlcy4iLCIuQ2F0ZWdvcmllcy4iXSwiYXBwIjoiYWRtaW5wb3J0YWwifQ==.5EKrHCfUjd8eMZ6Uo0lOTr0H4IEn8STQEESoWbmMaI'
  );

  const featureToggleService = useFeatureToggleService();
  featureToggleService.mockFeatureToggle(FeatureToggles.PIM_TRANSLATE_LABELS, true);
  featureToggleService.mockFeatureToggle(FeatureToggles.PIM_BULK_OPERATIONS_PAGE, true);
}
