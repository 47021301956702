import React from 'react';
import {
  useFeatureToggleService,
  useAnalyticsService,
  useAuthenticationService
} from 'admin-portal-shared-services';
import {
  StylesProvider,
  createGenerateClassName,
  ThemeProvider,
  CssBaseline
} from '@material-ui/core';
import AppRouter from './AppRouter';
import theme from '../themes';

interface EnvProps {
  PIM_SEGMENT_KEY: string;
}

function App({ PIM_SEGMENT_KEY }: EnvProps): JSX.Element {
  const jwtDecoded = useAuthenticationService().parseJwt();
  const analyticsService = useAnalyticsService();
  useFeatureToggleService().loadToggles();
  analyticsService.load(PIM_SEGMENT_KEY);

  const traits = {
    country: jwtDecoded.country,
    email: jwtDecoded.email,
    first_name: jwtDecoded.given_name,
    last_name: jwtDecoded.family_name
  };

  analyticsService.identify(traits);

  const generateClassName = createGenerateClassName({
    productionPrefix: 'pim',
    seed: 'pim'
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <StylesProvider generateClassName={generateClassName}>
          <AppRouter />
        </StylesProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
