import { createTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  palette,
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: 'rgba(0, 0, 0, 0.87)',
          lineHeight: '1.43',
          letterSpacing: '0.01071em',
          backgroundColor: '#fafafa',
          fontSize: '0.875rem',

          '&::backdrop': {
            backgroundColor: '#fafafa'
          }
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        letterSpacing: '0',
        lineHeight: '24px',
        fontSize: '16px'
      },
      contained: {
        '&:hover': {
          boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)'
        }
      }
    },
    MuiCheckbox: {
      root: {
        color: palette.black
      }
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: palette.black
        }
      }
    },
    MuiDialogTitle: {
      root: {
        color: palette.text.primary,
        fontWeight: 600,
        fontSize: '24px',
        letterSpacing: '0px',
        lineHeight: '28px'
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: '2.5px 6px'
        },
        flexWrap: 'nowrap',
        fontSize: '16px',
        letterSpacing: 0,
        lineHeight: '20px',
        resize: 'none',
        '& input::placeholder': {
          color: '#707372',
          opacity: 1
        },
        '& input:focus::placeholder': {
          opacity: 0
        }
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#B9BCC1'
      }
    },
    MuiInputBase: {
      root: {
        color: palette.black
      }
    }
  }
});

export default theme;
