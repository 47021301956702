import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './bootstrap/App';
import { setupLocalEnv } from './setupLocal';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!window.Cypress && process.env.STANDALONE && process.env.NODE_ENV === 'development') {
  setupLocalEnv();
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App
});

export const { bootstrap } = reactLifecycles;
export const { mount } = reactLifecycles;
export const { unmount } = reactLifecycles;
