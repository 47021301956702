import MessageMap from '../domains/MessageMap';

const ptBR: MessageMap = {
  ProductRoute: {
    LABEL: 'Produtos'
  },
  AttributesRoute: {
    LABEL: 'Atributos'
  },
  CategoriesRoute: {
    LABEL: 'Classes'
  },
  BulkOperationsRoute: {
    LABEL: 'Edição em grupo'
  }
};

export default ptBR;
