import MessageMap from '../domains/MessageMap';

const en: MessageMap = {
  ProductRoute: {
    LABEL: 'Products'
  },
  AttributesRoute: {
    LABEL: 'Attributes'
  },
  CategoriesRoute: {
    LABEL: 'Classes'
  },
  BulkOperationsRoute: {
    LABEL: 'Bulk Editor'
  }
};

export default en;
