import MessageMap from '../domains/MessageMap';

const es419: MessageMap = {
  ProductRoute: {
    LABEL: 'Productos'
  },
  AttributesRoute: {
    LABEL: 'Atributos'
  },
  CategoriesRoute: {
    LABEL: 'Clases'
  },
  BulkOperationsRoute: {
    LABEL: 'Editor de volumen'
  }
};

export default es419;
