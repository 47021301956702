import { useFeatureToggle, usePreferredLanguageV2 } from 'admin-portal-shared-services';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PIM_TRANSLATE_LABELS } from '../config/featureToggles';
import { updateIntlProvider } from '../i18n/formatters';
import './global.css';

function AppRouter(): JSX.Element {
  const translateLabelsFT = useFeatureToggle(PIM_TRANSLATE_LABELS);

  const { preferredLanguage, isLoading } = usePreferredLanguageV2();

  React.useEffect(() => {
    if (translateLabelsFT && preferredLanguage && !isLoading) {
      updateIntlProvider(preferredLanguage);
    }
  }, [translateLabelsFT, preferredLanguage]);

  return (
    <BrowserRouter>
      <></>
    </BrowserRouter>
  );
}

export default AppRouter;
